import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WelcomePage from './components/WelcomePage';
import MoreImages from './components/moreimages'; // Correct the import statement to match the filename
import Palettes from './components/Palettes'; // Import Palettes component
import AboutUs from './components/aboutus'; // Correct the import statement to match the filename
import Search from './components/search'; // Import Search component
import SearchByDate from './components/SearchByDate'; // Import SearchByDate component
import SearchByTags from './components/SearchByTags'; // Import SearchByTags component
import SearchByRandom from './components/SearchByRandom'; // Import SearchByRandom component
import SearchByColor from './components/SearchByColor'; // Import SearchByColor component
import './index.css';
import './colors.css'; // Import the new color classes

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/more-images" element={<MoreImages />} /> {/* Update the route to MoreImages */}
        <Route path="/palettes" element={<Palettes />} /> {/* Add the route for Palettes */}
        <Route path="/aboutus" element={<AboutUs />} /> {/* Correct the route to match the filename */}
        <Route path="/search" element={<Search />} /> {/* Add the route for Search */}
        <Route path="/search/date" element={<SearchByDate />} /> {/* Add the route for SearchByDate */}
        <Route path="/search/tags" element={<SearchByTags />} /> {/* Add the route for SearchByTags */}
        <Route path="/search/random" element={<SearchByRandom />} /> {/* Add the route for SearchByRandom */}
        <Route path="/search/color" element={<SearchByColor />} /> {/* Add the route for SearchByColor */}
      </Routes>
    </Router>
  </React.StrictMode>
);
