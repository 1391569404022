import React from 'react';
import { Loader } from 'lucide-react';

export default function LoadingState({ message = "Loading..." }) {
  return (
    <div className="flex items-center justify-center p-8">
      <Loader className="w-6 h-6 text-accent animate-spin mr-3" />
      <span className="text-muted">{message}</span>
    </div>
  );
}
