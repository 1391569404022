import React from "react";
import { Palette, Search, Eye, ArrowRight } from "lucide-react"; // Replace Sparkles with Search
import { useNavigate } from 'react-router-dom'; // Add this import
import backgroundPattern from '../assets/1_0014.png'; // Add this import

export default function HeroSection() {
  const navigate = useNavigate(); // Add this
  const scrollToDaily = () => {
    const dailySection = document.getElementById('daily-image-section');
    if (dailySection) {
      dailySection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header 
      className="relative text-center py-32 px-4 sm:px-6 lg:px-8 overflow-hidden bg-repeat"
      style={{ 
        backgroundImage: `linear-gradient(rgba(var(--bg-color-rgb), 0.8), rgba(var(--bg-color-rgb), 0.8)), url(${backgroundPattern})`,
        backgroundSize: 'auto', // Adjust this value to control the tile size
        backgroundColor: 'var(--bg-color)', // Add this for background color behind the pattern
      }}
    >
      {/* Update gradient overlay for light/dark modes */}
      <div className="absolute inset-0 bg-gradient-to-b from-bg-color/90 via-transparent to-bg-color/90 pointer-events-none" />
      
      {/* Animated background elements */}
      <div className="absolute inset-0 bg-gradient-to-tr from-accent/5 via-accent/10 to-transparent pointer-events-none" />
      <div className="absolute top-0 left-0 w-full h-full">
        <div className="absolute top-20 left-10 w-32 h-32 bg-accent/5 rounded-full blur-xl animate-pulse" />
        <div className="absolute bottom-10 right-10 w-40 h-40 bg-accent/10 rounded-full blur-xl animate-pulse delay-700" />
      </div>
      
      {/* Main content */}
      <div className="max-w-5xl mx-auto relative z-10">
  <div className="animate-fadeSlideUp">
    <span className="inline-block px-4 py-2 rounded-full bg-accent/10 text-accent mb-4">
      Daily Design Inspiration
    </span>
    <h1 className="text-6xl md:text-7xl font-extrabold text-base mb-6 tracking-tight leading-tight">
      Transform Your 
      <span className="text-accent block mt-2">Creative Vision</span>
    </h1>
    <p className="text-xl text-muted mb-12 leading-relaxed max-w-2xl mx-auto">
      Discover a world of design possibilities. Get inspired by our curated collection 
      of fantastic designs, color palettes, and creative innovations. 100% free and made to inspire creative artists with a huge collection of the best and unique images.
    </p>
    
    <button 
      onClick={scrollToDaily}
      className="group inline-flex items-center gap-2 bg-accent text-black px-6 py-3 rounded-lg hover:bg-accent/90 transition-all"
      aria-label="Explore Designs"
    >
      Explore Designs
      <ArrowRight className="group-hover:translate-x-1 transition-transform" />
    </button>
  </div>
        
        {/* Stats */}
        <div className="flex justify-center gap-12 mt-16 mb-20">
          {[
            { number: "5.2K+", label: "Daily Visitors" },
            { number: "400+", label: "Design Inspirations" },
            { number: "400+", label: "Color Palettes" }
          ].map((stat, index) => (
            <div key={index} className="text-center">
              <div className="text-3xl font-bold text-accent mb-1">{stat.number}</div>
              <div className="text-muted text-sm">{stat.label}</div>
            </div>
          ))}
        </div>
        
        {/* Feature grid */}
        <div className="grid md:grid-cols-3 gap-8 mt-8">
          {[
            {
              icon: <Palette className="h-12 w-12 text-accent" />,
              title: "Color Palettes",
              desc: "Handpicked color combinations to inspire your next project",
              onClick: () => navigate('/palettes') // Add click handler for palettes
            },
            {
              icon: <Eye className="h-12 w-12 text-accent" />,
              title: "Visual Trends",
              desc: "Stay ahead with the latest design patterns and styles",
              onClick: () => navigate('/more-images') // Add navigation to more-images
            },
            {
              icon: <Search className="h-12 w-12 text-accent" />, // Changed icon
              title: "Search for Images",
              desc: "Find the perfect inspiration using our powerful search tools",
              onClick: () => navigate('/search') // Add click handler
            }
          ].map((feature, index) => (
            <div 
              key={index} 
              className="bg-card p-6 rounded-xl hover:scale-105 transition-transform cursor-pointer pb-14"
              onClick={feature.onClick} // Add click handler
            >
              <div className="bg-accent/10 w-24 h-24 rounded-xl flex items-center justify-center mb-4 mx-auto">
                {feature.icon}
              </div>
              <h3 className="font-bold text-base mb-2">{feature.title}</h3>
              <p className="text-muted text-sm">{feature.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </header>
  );
}