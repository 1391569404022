import React, { useState, useEffect } from "react";
import { ImageIcon } from "lucide-react";
import Navbar from './Navbar'; // Import Navbar component
import Footer from './Footer'; // Import Footer component
import "../colors.css";

export default function Palettes() {
  const [palettes, setPalettes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("https://nitter.com/palettes.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setPalettes(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching palettes:", err);
        setError(err.message);
        setLoading(false);
      });
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(`Copied ${text} to clipboard`);
  };

  if (loading) {
    return (
      <p className="text-center text-gray-500 p-6 text-lg animate-pulse">
        Loading...
      </p>
    );
  }

  if (error) {
    return (
      <p className="text-center text-red-500 p-6 text-lg">
        Error: {error}
      </p>
    );
  }

  return (
    <>
      <Navbar />
      <section className="mx-auto my-8 max-w-7xl bg-base rounded-2xl shadow-lg overflow-hidden">
        <h2
          className="
            text-center 
            text-3xl 
            sm:text-4xl 
            font-extrabold 
            py-8 
            bg-base/90
          "
        >
          Color Palettes
          <br />
          <span className="text-base font-semibold">
            click the colors to copy
          </span>
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 p-8">
          {palettes.map((palette, index) => (
            <div
              key={palette.id || index}
              className="
                bg-card
                rounded-xl
                shadow-md
                transition
                transform
                hover:-translate-y-1
                hover:shadow-xl
                p-6
                min-h-[12rem]
                flex
                flex-col
                justify-between
                items-center
                text-center
              "
            >
              <div className="flex flex-col justify-between h-full w-full">
                <h3 className="text-sm sm:text-base pt-4 font-light text-base mb-4 whitespace-normal break-words flex-grow-0 flex-shrink-0 w-full">
                  {palette.headlines}
                </h3>
                <div className="flex flex-wrap gap-1 flex-grow justify-center py-2 items-end">
                  {palette.hexcolors.map((color, idx) => (
                    <div
                      key={idx}
                      className="
                        w-6 
                        h-6 
                        rounded-sm 
                        cursor-pointer
                        transition
                        hover:opacity-90
                      "
                      style={{ backgroundColor: color }}
                      onClick={() => copyToClipboard(color)}
                    />
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
}
