import React from 'react';
import { X } from 'lucide-react';

export default function Lightbox({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-8" 
      onClick={onClose}
    >
      <div 
        className="bg-card rounded-xl overflow-hidden shadow-2xl max-w-6xl w-full max-h-[90vh] overflow-y-auto" 
        onClick={e => e.stopPropagation()}
      >
        <button 
          className="absolute top-4 right-4 p-2 bg-black/50 text-white rounded-full hover:bg-black/75 transition-colors"
          onClick={onClose}
        >
          <X className="w-6 h-6" />
        </button>
        {children}
      </div>
    </div>
  );
}
