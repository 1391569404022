import React, { useState, useEffect } from "react";
import { X, Clipboard, ArrowLeft } from "lucide-react"; // Import X, Clipboard, and ArrowLeft components
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Navbar from './Navbar'; // Import Navbar component
import Footer from './Footer'; // Import Footer component

export default function SearchByColor() {
  const [color, setColor] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [searched, setSearched] = useState(false); // Add state to track if search button was pressed
  const [paletteColors, setPaletteColors] = useState(["#000000", "#FFFFFF"]); // Add state to store palette colors with black and white
  const navigate = useNavigate(); // Initialize navigate

  const fetchPaletteColors = () => {
    // Fetch 28 random colors from random entries from the palettes.php endpoint
    fetch('https://nitter.com/palettes.php')
      .then((response) => response.json())
      .then((data) => {
        const colors = data.flatMap(entry => entry.hexcolors)
                            .filter(color => color !== "#000000" && color !== "#FFFFFF") // Exclude black and white
                            .sort(() => 0.5 - Math.random()) // Shuffle the array
                            .slice(0, 28); // Select the first 28 colors
        setPaletteColors(["#000000", "#FFFFFF", ...colors]);
      })
      .catch((err) => {
        console.error("Error fetching palette colors:", err);
      });
  };

  useEffect(() => {
    fetchPaletteColors();
  }, []);

  // Handle color changes from the input
  const handleColorChange = (event) => {
    setColor(event.target.value);
    setSearched(false); // Reset searched state when color changes
  };

  // Fetch images by selected color
  const handleSearch = (selectedColor) => {
    const searchColor = (selectedColor || color).replace('#', '');
    if (!searchColor) {
      alert("Please enter a color first.");
      return;
    }
    setLoading(true);
    setError(null);
    setImages([]);
    setSearched(true); // Set searched to true when search button is pressed

    // Fetch data from the proxy server
    fetch(`https://nitter.com/SearchByColor.php?color=${searchColor}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          setError(data.error);
        } else {
          setImages(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching images by color:", err);
        setError("An error occurred while fetching data.");
        setLoading(false);
      });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(`Copied ${text} to clipboard`);
  };

  const openLightbox = (image) => {
    setSelectedImage(image);
    document.body.style.overflow = 'hidden';
  };

  const closeLightbox = () => {
    setSelectedImage(null);
    document.body.style.overflow = 'unset';
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto p-4 mt-4">
        <button
          onClick={() => navigate('/search')}
          className="flex items-center gap-2 px-4 py-2 bg-accent text-black rounded-lg hover:bg-accent/90 transition-all mb-6"
        >
          <ArrowLeft className="w-5 h-5" />
          Back to Search
        </button>
        <h2 className="text-4xl font-bold text-center mb-8 text-accent">Search by Color</h2>
        <p className="text-center text-base font-light mb-6 text-muted">
          Find images based on specific colors. Enter a hex color (e.g., f4422c without #) and click "Search" to see the results.
        </p>
        {/* Color Input and Search Button */}
        <div className="flex items-center gap-2 mb-6 justify-center">
          <input
            type="text"
            value={color}
            onChange={handleColorChange}
            placeholder="Enter hex color (e.g., f4422c)"
            className="px-4 py-2 bg-gray-200 text-black rounded border border-accent" // Update text color and border color
          />
          <button
            onClick={() => handleSearch()}
            className="px-4 py-2 bg-accent text-black rounded hover:bg-accent/90 transition-all" // Update button color
          >
            Search
          </button>
        </div>

        {/* Color Picker */}
        <div className="flex justify-center mb-6">
          <div className="grid grid-cols-5 gap-2">
            {paletteColors.map((color) => (
              <div
                key={color}
                className="w-10 h-10 rounded-md cursor-pointer border border-gray-300 hover:shadow-md transition-shadow"
                style={{ backgroundColor: color }}
                onClick={() => handleSearch(color)}
              />
            ))}
          </div>
        </div>

        {/* Fetch New Colors Button */}
        <div className="flex justify-center mb-6">
          <button
            onClick={fetchPaletteColors}
            className="px-4 py-2 bg-accent text-black rounded hover:bg-accent/90 transition-all"
          >
            30 NEW COLORS
          </button>
        </div>

        {/* Loading / Error / Results */}
        {loading && <p className="text-white">Searching...</p>}
        {error && <p className="text-red-500">{error}</p>}

        {!loading && !error && images.length > 0 && (
          <div className="flex justify-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-4">
              {images.map((img, index) => (
                <div
                  key={img.id || index}
                  className="relative w-full h-full overflow-hidden cursor-pointer group"
                  onClick={() => openLightbox(img)}
                >
                  <img
                    src={img.link?.startsWith('http') ? img.link : `https://nitter.com/${img.link}`}
                    alt={img.alttext || "No alt text"}
                    className="w-full h-full object-contain transition-transform duration-700 group-hover:scale-105"
                    onError={(e) => {
                      e.target.src = "https://via.placeholder.com/80?text=Coming+Soon";
                    }}
                  />
                  <div className="absolute inset-0 bg-black/60 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <button 
                      className="px-4 py-2 bg-accent text-black rounded-lg transform scale-95 group-hover:scale-100 transition-transform duration-300"
                      onClick={(e) => {
                        e.preventDefault();
                        openLightbox(img);
                      }}
                    >
                      OPEN
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {searched && !loading && !error && images.length === 0 && (
          <p className="text-white text-center">
            No images found for color: {color}.
          </p>
        )}

        {selectedImage && (
          <div className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-8" onClick={closeLightbox}>
            <div className="bg-card rounded-xl overflow-hidden shadow-2xl max-w-6xl w-full max-h-[90vh] overflow-y-auto" onClick={e => e.stopPropagation()}>
              <div className="relative">
                <img
                  src={selectedImage.link?.startsWith('http') ? selectedImage.link : `https://nitter.com/${selectedImage.link}`}
                  alt={selectedImage.alttext}
                  className="w-full object-contain"
                />
                <button 
                  className="absolute top-4 right-4 p-2 bg-black/50 text-white rounded-full hover:bg-black/75 transition-colors"
                  onClick={closeLightbox}
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              
              <div className="p-8">
                <h2 className="text-3xl font-bold text-base mb-6">{selectedImage.headlines}</h2>
                
                {selectedImage.description && (
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                      Description
                    </h3>
                    <p className="text-muted">{selectedImage.description}</p>
                  </div>
                )}

                {selectedImage.style && (
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                      Style
                    </h3>
                    <p className="text-muted">{selectedImage.style}</p>
                  </div>
                )}

                {selectedImage.tags?.length > 0 && (
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                      Tags
                    </h3>
                    <div className="flex flex-wrap gap-2">
                      {selectedImage.tags.map((tag, idx) => (
                        <span 
                          key={idx} 
                          className="bg-gray-700 text-white px-3 py-1 rounded-full text-sm"
                        >
                          {tag} {/* Removed the '#' prefix */}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                {selectedImage.hexcolors?.length > 0 && (
                  <div>
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                      Color Palette
                    </h3>
                    <div className="flex flex-wrap gap-4">
                      {selectedImage.hexcolors.map((color, idx) => (
                        <div key={idx} className="flex flex-col items-center">
                          <div
                            className="w-12 h-12 rounded-full shadow-md hover:scale-110 transition-transform cursor-pointer"
                            style={{ backgroundColor: color }}
                            onClick={() => copyToClipboard(color)}
                          />
                          <span className="text-muted text-sm mt-1">{color}</span>
                          <button
                            className="mt-1 p-1 bg-accent/10 hover:bg-accent/20 rounded-full transition-colors"
                            onClick={() => copyToClipboard(color)}
                          >
                            <Clipboard className="w-4 h-4 text-accent" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
