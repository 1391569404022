import React, { useState, useEffect } from "react";
import { SunIcon, MoonIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import nitterLogo from '../assets/nitterlogo.svg'; // Import the SVG file
import nitterLogo2 from '../assets/nitterlogo_2.svg'; // Import the SVG file

export default function Navbar() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Check the initial theme from localStorage or default to dark mode
    return localStorage.getItem('theme') === 'light' ? false : true;
  });

  useEffect(() => {
    // Apply the theme class to the HTML element
    const html = document.documentElement;
    if (isDarkMode) {
      html.classList.add('dark');
      html.classList.remove('light');
      localStorage.setItem('theme', 'dark');
    } else {
      html.classList.add('light');
      html.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-card shadow-lg sticky top-0 z-50">
      <div className="container mx-auto px-1 py-3 flex items-center justify-between">
        <Link to="/" className="flex items-center  font-light text-accent"> {/* Change font weight to light */}
          <img src={nitterLogo2} alt="Nitter Logo" className="h-12  mr-4" /> {/* Add margin to the logo */}
       
        </Link>

        {/* Menu links + dark mode on large screens */}
        <div className="hidden lg:flex items-center gap-6">
          <Link
            to="/"
            className="relative group transition-colors duration-300 text-muted hover:text-accent"
          >
            Home
            <span className="absolute left-0 -bottom-1 w-full h-0.5 bg-accent transform scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100"></span>
          </Link>

          <Link
            to="/more-images"
            className="relative group transition-colors duration-300 text-muted hover:text-accent"
          >
            More Images
            <span className="absolute left-0 -bottom-1 w-full h-0.5 bg-accent transform scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100"></span>
          </Link>

          <Link
            to="/palettes"
            className="relative group transition-colors duration-300 text-muted hover:text-accent"
          >
            Palettes
            <span className="absolute left-0 -bottom-1 w-full h-0.5 bg-accent transform scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100"></span>
          </Link>

          <Link
            to="/aboutus"
            className="relative group transition-colors duration-300 text-muted hover:text-accent"
          >
            About Us
            <span className="absolute left-0 -bottom-1 w-full h-0.5 bg-accent transform scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100"></span>
          </Link>

          <Link
            to="/search"
            className="relative group transition-colors duration-300 text-muted hover:text-accent"
          >
            Search
            <span className="absolute left-0 -bottom-1 w-full h-0.5 bg-accent transform scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100"></span>
          </Link>

          <button
            onClick={toggleDarkMode}
            className="bg-gray-700 hover:bg-gray-600 text-white p-2 rounded-full shadow transition flex items-center"
          >
            {isDarkMode ? (
              <SunIcon className="h-6 w-6 text-yellow-400" />
            ) : (
              <MoonIcon className="h-6 w-6 text-blue-400" />
            )}
          </button>
        </div>

        {/* Hamburger toggle on mobile */}
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="lg:hidden p-2 rounded-md text-accent hover:bg-accent/10 transition"
        >
          {isMenuOpen ? "X" : "☰"}
        </button>
      </div>

      {/* Mobile menu when open */}
      {isMenuOpen && (
        <div className="lg:hidden bg-card">
          <div className="container mx-auto px-4 pb-4 flex flex-col gap-6">
            <Link
              to="/"
              className="relative group transition-colors duration-300 text-muted hover:text-accent"
              onClick={() => setIsMenuOpen(false)}
            >
              Home
              <span className="absolute left-0 -bottom-1 w-full h-0.5 bg-accent transform scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100"></span>
            </Link>

            <Link
              to="/more-images"
              className="relative group transition-colors duration-300 text-muted hover:text-accent"
              onClick={() => setIsMenuOpen(false)}
            >
              More Images
              <span className="absolute left-0 -bottom-1 w-full h-0.5 bg-accent transform scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100"></span>
            </Link>

            <Link
              to="/palettes"
              className="relative group transition-colors duration-300 text-muted hover:text-accent"
              onClick={() => setIsMenuOpen(false)}
            >
              Palettes
              <span className="absolute left-0 -bottom-1 w-full h-0.5 bg-accent transform scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100"></span>
            </Link>

            <Link
              to="/aboutus"
              className="relative group transition-colors duration-300 text-muted hover:text-accent"
              onClick={() => setIsMenuOpen(false)}
            >
              About Us
              <span className="absolute left-0 -bottom-1 w-full h-0.5 bg-accent transform scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100"></span>
            </Link>

            <Link
              to="/search"
              className="relative group transition-colors duration-300 text-muted hover:text-accent"
              onClick={() => setIsMenuOpen(false)}
            >
              Search
              <span className="absolute left-0 -bottom-1 w-full h-0.5 bg-accent transform scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100"></span>
            </Link>

            <button
              onClick={toggleDarkMode}
              className="bg-gray-700 hover:bg-gray-600 text-white p-2 rounded-full shadow transition flex items-center"
            >
              {isDarkMode ? (
                <SunIcon className="h-6 w-6 text-yellow-400" />
              ) : (
                <MoonIcon className="h-6 w-6 text-blue-400" />
              )}
            </button>
          </div>
        </div>
      )}
    </nav>
  );
}