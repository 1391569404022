import React, { useState, useEffect } from "react";
import { X, Clipboard, ArrowLeft } from "lucide-react"; // Import X, Clipboard, and ArrowLeft components
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Navbar from './Navbar'; // Import Navbar component
import Footer from './Footer'; // Import Footer component

export default function SearchByTags() {
  const [tags, setTags] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [searched, setSearched] = useState(false); // Add state to track if search button was pressed
  const [tagInput, setTagInput] = useState(""); // Add new input state
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    // Fetch 50 random tags from the allimages.php endpoint
    fetch('https://nitter.com/allimages.php')
      .then((response) => response.json())
      .then((data) => {
        const allTags = data.flatMap(entry => entry.tags)
                             .filter((tag, index, self) => self.indexOf(tag) === index) // Remove duplicates
                             .sort(() => 0.5 - Math.random()) // Shuffle the array
                             .slice(0, 40); // Select the first 50 tags
        setTags(allTags);
      })
      .catch((err) => {
        console.error("Error fetching tags:", err);
      });
  }, []);

  const fetchNewTags = () => {
    setLoading(true); // Add loading state while fetching
    fetch('https://nitter.com/allimages.php')
      .then((response) => response.json())
      .then((data) => {
        if (!Array.isArray(data)) {
          throw new Error('Invalid data format received');
        }
        const allTags = data.flatMap(entry => entry.tags || [])
                           .filter(tag => tag && typeof tag === 'string') // Ensure valid tags
                           .filter((tag, index, self) => self.indexOf(tag) === index)
                           .sort(() => 0.5 - Math.random())
                           .slice(0, 40);
        console.log('Fetched new tags:', allTags); // Debug log
        setTags(allTags);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching new tags:", err);
        setError("Failed to load new tags");
        setLoading(false);
      });
  };

  // Fetch images by selected tag
  const handleSearch = (selectedTag) => {
    const searchTag = selectedTag || tagInput;
    if (!searchTag) {
      alert("Please enter or select a tag first.");
      return;
    }
    setLoading(true);
    setError(null);
    setImages([]);
    setSearched(true); // Set searched to true when search button is pressed

    // Fetch data from the proxy server
    fetch(`https://nitter.com/SearchByTags.php?tags=${encodeURIComponent(searchTag)}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          setError(data.error);
        } else {
          setImages(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching images by tag:", err);
        setError("An error occurred while fetching data.");
        setLoading(false);
      });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(`Copied ${text} to clipboard`);
  };

  const openLightbox = (image) => {
    setSelectedImage(image);
    document.body.style.overflow = 'hidden';
  };

  const closeLightbox = () => {
    setSelectedImage(null);
    document.body.style.overflow = 'unset';
  };

  const handleInputChange = (event) => {
    setTagInput(event.target.value);
    setSearched(false);
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto p-4 mt-4">
        <button
          onClick={() => navigate('/search')}
          className="flex items-center gap-2 px-4 py-2 bg-accent text-black rounded-lg hover:bg-accent/90 transition-all mb-6"
        >
          <ArrowLeft className="w-5 h-5" />
          Back to Search
        </button>
        <h2 className="text-4xl font-bold text-center mb-8 text-accent">Search by Tags</h2>
        <p className="text-center text-base font-light mb-12 text-muted max-w-2xl mx-auto">
          Search our collection using tags. Enter a tag or select from the popular tags below.
        </p>

        {/* Tag Input and Search Button */}
        <div className="flex flex-col items-center gap-4 mb-12">
          <div className="flex items-center gap-4">
            <input
              type="text"
              value={tagInput}
              onChange={handleInputChange}
              placeholder="Enter a tag..."
              className="px-6 py-3 bg-card text-accent rounded-lg border border-accent/20 focus:border-accent 
                        transition-all outline-none hover:border-accent/50"
            />
            <button
              onClick={() => handleSearch()}
              className="px-8 py-3 bg-accent text-black rounded-lg hover:bg-accent/90 
                       transition-all font-medium"
            >
              Search Tags
            </button>
          </div>
        </div>

        {/* Popular Tags Section */}
        <div className="mb-12">
          <h3 className="text-center text-lg font-medium text-accent mb-4">Popular Tags</h3>
          <div className="flex flex-wrap justify-center gap-2 min-h-[100px]"> {/* Add min-height */}
            {loading ? (
              <p className="text-accent">Loading new tags...</p>
            ) : (
              tags.map((tag) => (
                <button
                  key={tag}
                  onClick={() => {
                    setTagInput(tag);
                    handleSearch(tag);
                  }}
                  className="px-4 py-2 bg-gray-700 text-white rounded-full text-sm 
                         hover:bg-gray-600 transition-colors"
                >
                  {tag}
                </button>
              ))
            )}
          </div>
          {/* Load New Tags button */}
          <div className="flex justify-center mt-6">
            <button
              onClick={fetchNewTags}
              disabled={loading}
              className={`px-8 py-3 bg-accent text-black rounded-lg 
                     transition-all font-medium flex items-center gap-2
                     ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-accent/90'}`}
            >
              {loading ? 'Loading...' : 'Load New Tags'}
            </button>
          </div>
        </div>

        {/* Loading / Error / Results */}
        {loading && <p className="text-white">Searching...</p>}
        {error && <p className="text-red-500">{error}</p>}

        {!loading && !error && images.length > 0 && (
          <div className="flex justify-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-4">
              {images.map((img, index) => (
                <div
                  key={img.id || index}
                  className="relative w-full h-full overflow-hidden cursor-pointer group"
                  onClick={() => openLightbox(img)}
                >
                  <img
                    src={img.link?.startsWith('http') ? img.link : `https://nitter.com/${img.link}`}
                    alt={img.alttext || "No alt text"}
                    className="w-full h-full object-contain transition-transform duration-700 group-hover:scale-105"
                    onError={(e) => {
                      e.target.src = "https://via.placeholder.com/80?text=Coming+Soon";
                    }}
                  />
                  <div className="absolute inset-0 bg-black/60 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <button 
                      className="px-4 py-2 bg-accent text-black rounded-lg transform scale-95 group-hover:scale-100 transition-transform duration-300"
                      onClick={(e) => {
                        e.preventDefault();
                        openLightbox(img);
                      }}
                    >
                      OPEN
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {searched && !loading && !error && images.length === 0 && (
          <p className="text-white text-center">
            No images found for tag: {tagInput}.
          </p>
        )}

        {selectedImage && (
          <div className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-8" onClick={closeLightbox}>
            <div className="bg-card rounded-xl overflow-hidden shadow-2xl max-w-6xl w-full max-h-[90vh] overflow-y-auto" onClick={e => e.stopPropagation()}>
              <div className="relative">
                <img
                  src={selectedImage.link?.startsWith('http') ? selectedImage.link : `https://nitter.com/${selectedImage.link}`}
                  alt={selectedImage.alttext}
                  className="w-full object-contain"
                />
                <button 
                  className="absolute top-4 right-4 p-2 bg-black/50 text-white rounded-full hover:bg-black/75 transition-colors"
                  onClick={closeLightbox}
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              
              <div className="p-8">
                <h2 className="text-3xl font-bold text-base mb-6">{selectedImage.headlines}</h2>
                
                {selectedImage.description && (
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                      Description
                    </h3>
                    <p className="text-muted">{selectedImage.description}</p>
                  </div>
                )}

                {selectedImage.style && (
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                      Style
                    </h3>
                    <p className="text-muted">{selectedImage.style}</p>
                  </div>
                )}

                {selectedImage.tags?.length > 0 && (
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                      Tags
                    </h3>
                    <div className="flex flex-wrap gap-2">
                      {selectedImage.tags.map((tag, idx) => (
                        <span 
                          key={idx} 
                          className="bg-gray-700 text-white px-3 py-1 rounded-full text-sm"
                        >
                          {tag} {/* Removed the '#' prefix */}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                {selectedImage.hexcolors?.length > 0 && (
                  <div>
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                      Color Palette
                    </h3>
                    <div className="flex flex-wrap gap-4">
                      {selectedImage.hexcolors.map((color, idx) => (
                        <div key={idx} className="flex flex-col items-center">
                          <div
                            className="w-12 h-12 rounded-full shadow-md hover:scale-110 transition-transform cursor-pointer"
                            style={{ backgroundColor: color }}
                            onClick={() => copyToClipboard(color)}
                          />
                          <span className="text-muted text-sm mt-1">{color}</span>
                          <button
                            className="mt-1 p-1 bg-accent/10 hover:bg-accent/20 rounded-full transition-colors"
                            onClick={() => copyToClipboard(color)}
                          >
                            <Clipboard className="w-4 h-4 text-accent" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
