import React from 'react';
import { NavLink } from 'react-router-dom';
import nitterLogo from '../assets/nitterlogo.svg'; // Import the SVG file

export default function Footer() {
  return (
    <footer className="bg-card text-center py-8 mt-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8"> {/* Changed to 3 columns */}
          {/* Sitemap */}
          <div>
            <h3 className="text-xl font-bold text-accent mb-4">Sitemap</h3>
            <ul className="space-y-2">
              <li>
                <NavLink to="/" className="text-muted hover:text-accent transition-colors duration-300">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/more-images" className="text-muted hover:text-accent transition-colors duration-300">
                  More Images
                </NavLink>
              </li>
              <li>
                <NavLink to="/palettes" className="text-muted hover:text-accent transition-colors duration-300">
                  Palettes
                </NavLink>
              </li>
              <li>
                <NavLink to="/search" className="text-muted hover:text-accent transition-colors duration-300">
                  Search
                </NavLink>
              </li>
              <li>
                <NavLink to="/aboutus" className="text-muted hover:text-accent transition-colors duration-300">
                  About Us
                </NavLink>
              </li>
            </ul>
          </div>

          {/* About */}
          <div>
            <img src={nitterLogo} alt="Nitter Logo" className="h-16 w-16 mx-auto mb-4" />
            <h3 className="text-xl font-bold text-accent mb-4">About Nitter</h3>
            <p className="text-muted">
              Nitter is your daily source of design inspiration. Explore our curated collection of stunning designs, color palettes, and creative innovations.
            </p>
          </div>

          {/* Contact Us */}
          <div>
            <h3 className="text-xl font-bold text-accent mb-4">Contact Us</h3>
            <p className="text-muted mb-4">
              Have questions or feedback? Learn more about nitter and get in touch with our team.
            </p>
           
            <NavLink to="/aboutus" className="inline-block bg-accent text-black px-4 py-2 rounded-lg hover:bg-accent/90 transition-all">
              Learn More
            </NavLink>
          </div>
        </div>

        <div className="mt-8 border-t border-gray-500 pt-4">
          <p className="text-muted">&copy; 2025 nitter.com. All rights reserved. Do not copy, or use, any of this content without permission from us.</p>
        </div>
      </div>
    </footer>
  );
}