import React, { useState, useEffect } from "react";
import { ImageIcon, Calendar, Palette, X, Clipboard } from "lucide-react";
import { format } from "date-fns";
import '../colors.css';

export default function MoreInspirations() {
  const [moreImages, setMoreImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetch("https://nitter.com/get_more_images.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          throw new Error(data.error);
        }
        // Ensure we have exactly 27 items
        const placeholdersNeeded = Math.max(0, 27 - data.length);
        const placeholders = Array(placeholdersNeeded).fill(null).map((_, index) => ({
          id: `placeholder-${index}`,
          link: null,
          headlines: 'Coming Soon',
          alttext: 'Placeholder image',
          isPlaceholder: true
        }));
        
        setMoreImages([...data, ...placeholders].slice(0, 27));
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching more images:", err);
        setError(err.message);
        setLoading(false);
      });
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(`Copied ${text} to clipboard`);
  };

  const openLightbox = (image) => {
    setSelectedImage(image);
    document.body.style.overflow = 'hidden';
  };

  const closeLightbox = () => {
    setSelectedImage(null);
    document.body.style.overflow = 'unset';
  };

  if (loading) return <p className="text-muted text-center p-4">Loading...</p>;
  if (error) return <p className="text-accent text-center p-4">Error: {error}</p>;

  return (
    <section className="rounded-xl overflow-hidden shadow-xl mx-12 my-4 bg-base">
      <h2 className="text-2xl font-bold text-base mb-4 p-8">More Inspirations</h2>
      <div className="grid grid-cols-3 xl:grid-cols-9 gap-4 p-8">
        {moreImages.map((image, index) => (
          <div key={image.id || index} className="bg-card rounded-xl overflow-hidden shadow-xl group relative">
            <div className="relative w-full aspect-video overflow-hidden">
              {image.isPlaceholder ? (
                <div className="w-full h-full bg-accent/5 flex items-center justify-center">
                  <ImageIcon className="w-6 h-6 text-accent/50" />
                </div>
              ) : (
                <>
                  <img
                    src={image.link?.startsWith('http') ? image.link : `https://nitter.com/${image.link}`}
                    alt={image.alttext}
                    className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
                    onError={(e) => {
                      e.target.src = "https://via.placeholder.com/400x225?text=Coming+Soon";
                    }}
                  />
                  {/* Hover overlay with OPEN button */}
                  <div className="absolute inset-0 bg-black/60 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <button 
                      className="px-4 py-2 bg-accent text-white rounded-lg transform scale-95 group-hover:scale-100 transition-transform duration-300 flex items-center gap-2"
                      onClick={(e) => {
                        e.preventDefault();
                        openLightbox(image);
                      }}
                    >
                      OPEN
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="p-3">
              <h3 className="text-sm font-bold text-base mb-2 truncate">
                {image.headlines}
              </h3>
              <div className="flex items-center gap-2 text-muted text-xs">
                <Calendar className="w-3 h-3" />
                <span>
                  {image.isPlaceholder 
                    ? '—' 
                    : format(new Date(image.date), 'MMM dd, yyyy')}
                </span>
              </div>
              {/* Remove or comment out the tags section in the main view */}
              {/* 
              {image.tags?.length > 0 && (
                <div className="mb-6">
                  <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                    Tags
                  </h3>
                  <div className="flex flex-wrap gap-2">
                    {image.tags.map((tag, idx) => (
                      <span 
                        key={idx} 
                        className="bg-accent/10 text-accent px-4 py-2 rounded-full text-sm hover:bg-accent/20 transition-colors cursor-pointer"
                      >
                        #{tag}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              */}
            </div>
          </div>
        ))}
      </div>

      {/* Updated Lightbox */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-8" onClick={closeLightbox}>
          <div className="bg-card rounded-xl overflow-hidden shadow-2xl max-w-6xl w-full max-h-[90vh] overflow-y-auto" onClick={e => e.stopPropagation()}>
            <div className="relative">
              <img
                src={selectedImage.link?.startsWith('http') ? selectedImage.link : `https://nitter.com/${selectedImage.link}`}
                alt={selectedImage.alttext}
                className="w-full aspect-video object-cover"
              />
              <button 
                className="absolute top-4 right-4 p-2 bg-black/50 text-white rounded-full hover:bg-black/75 transition-colors"
                onClick={closeLightbox}
              >
                <X className="w-6 h-6" />
              </button>
            </div>
            
            <div className="p-8">
              <div className="flex items-center gap-4 text-muted mb-6">
                <Calendar className="w-5 h-5" />
                <span>{format(new Date(selectedImage.date), 'MMMM dd, yyyy')}</span>
              </div>
              
              <h2 className="text-3xl font-bold text-base mb-6">{selectedImage.headlines}</h2>
              
              {selectedImage.description && (
                <div className="mb-6">
                  <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                    Description
                  </h3>
                  <p className="text-muted">{selectedImage.description}</p>
                </div>
              )}

              {selectedImage.style && (
                <div className="mb-6">
                  <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                    Style
                  </h3>
                  <p className="text-muted">{selectedImage.style}</p>
                </div>
              )}

              {selectedImage.tags?.length > 0 && (
                <div className="mb-6">
                  <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                    Tags
                  </h3>
                  <div className="flex flex-wrap gap-2">
                    {selectedImage.tags.map((tag, idx) => (
                      <span 
                        key={idx} 
                        className="bg-gray-700 text-white px-3 py-1 rounded-full text-sm"
                      >
                        {tag} {/* Removed the '#' prefix */}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              {selectedImage.hexcolors?.length > 0 && (
                <div>
                  <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                    Color Palette
                  </h3>
                  <div className="flex flex-wrap gap-4">
                    {selectedImage.hexcolors.map((color, idx) => (
                      <div key={idx} className="flex flex-col items-center">
                        <div
                          className="w-12 h-12 rounded-full shadow-md hover:scale-110 transition-transform cursor-pointer"
                          style={{ backgroundColor: color }}
                          onClick={() => copyToClipboard(color)}
                        />
                        <span className="text-muted text-sm mt-1">{color}</span>
                        <button
                          className="mt-1 p-1 bg-accent/10 hover:bg-accent/20 rounded-full transition-colors"
                          onClick={() => copyToClipboard(color)}
                        >
                          <Clipboard className="w-4 h-4 text-accent" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
}