import React, { useState, useEffect, useRef } from "react";
import { ImageIcon, X, Clipboard } from "lucide-react"; // Import Clipboard icon
import '../colors.css';
import Navbar from './Navbar'; // Import Navbar component
import Footer from './Footer';

export default function MoreImages() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const observer = useRef();

  useEffect(() => {
    fetch("https://nitter.com/allimages.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setImages(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching images:", err);
        setError(err.message);
        setLoading(false);
      });
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(`Copied ${text} to clipboard`);
  };

  const openLightbox = (image) => {
    setSelectedImage(image);
    document.body.style.overflow = 'hidden';
  };

  const closeLightbox = () => {
    setSelectedImage(null);
    document.body.style.overflow = 'unset';
  };

  const imageRef = useRef([]);
  const handleObserver = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const img = entry.target;
        img.src = img.dataset.src;
        observer.current.unobserve(img);
      }
    });
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1
    });

    imageRef.current.forEach(img => {
      if (img) observer.current.observe(img);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [images]);

  if (loading) return <p className="text-muted text-center p-4">Loading...</p>;
  if (error) return <p className="text-accent text-center p-4">Error: {error}</p>;

  return (
    <>
      <Navbar />
      <section className="rounded-xl overflow-hidden shadow-xl mx-12 my-4 bg-base">
        <h2 className="text-4xl font-bold text-center mb-2 p-8">More Images</h2>
        <p className="text-center text-base font-light mb-8">Explore our extensive collection of images. Click on any image to view more details and see the full-size version.</p>
        <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-10 xl:grid-cols-12 gap-0 p-0">
          {images.map((image, index) => (
            <div key={image.id || index} className="relative w-full h-full overflow-hidden cursor-pointer group" onClick={() => openLightbox(image)}>
              <img
                ref={el => imageRef.current[index] = el}
                data-src={image.link?.startsWith('http') ? image.link : `https://nitter.com/${image.link}`}
                alt={image.alttext}
                className="w-full h-full object-contain transition-transform duration-700 group-hover:scale-105"
                onError={(e) => {
                  e.target.src = "https://via.placeholder.com/80?text=Coming+Soon";
                }}
              />
              <div className="absolute inset-0 bg-black/60 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <button 
                  className="px-4 py-2 bg-accent text-black rounded-lg transform scale-95 group-hover:scale-100 transition-transform duration-300"
                  onClick={(e) => {
                    e.preventDefault();
                    openLightbox(image);
                  }}
                >
                  OPEN
                </button>
              </div>
            </div>
          ))}
        </div>

        {selectedImage && (
          <div className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-8" onClick={closeLightbox}>
            <div className="bg-card rounded-xl overflow-hidden shadow-2xl max-w-6xl w-full max-h-[90vh] overflow-y-auto" onClick={e => e.stopPropagation()}>
              <div className="relative">
                <img
                  src={selectedImage.link?.startsWith('http') ? selectedImage.link : `https://nitter.com/${selectedImage.link}`}
                  alt={selectedImage.alttext}
                  className="w-full object-contain"
                />
                <button 
                  className="absolute top-4 right-4 p-2 bg-black/50 text-white rounded-full hover:bg-black/75 transition-colors"
                  onClick={closeLightbox}
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              
              <div className="p-8">
                <h2 className="text-3xl font-bold text-base mb-6">{selectedImage.headlines}</h2>
                
                {selectedImage.description && (
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                      Description
                    </h3>
                    <p className="text-muted">{selectedImage.description}</p>
                  </div>
                )}

                {selectedImage.style && (
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                      Style
                    </h3>
                    <p className="text-muted">{selectedImage.style}</p>
                  </div>
                )}

                {selectedImage.tags?.length > 0 && (
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                      Tags
                    </h3>
                    <div className="flex flex-wrap gap-2">
                      {selectedImage.tags.map((tag, idx) => (
                        <span 
                          key={idx} 
                          className="bg-gray-700 text-white px-3 py-1 rounded-full text-sm"
                        >
                          {tag} {/* Removed the '#' prefix */}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                {selectedImage.hexcolors?.length > 0 && (
                  <div>
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                      Color Palette
                    </h3>
                    <div className="flex flex-wrap gap-4">
                      {selectedImage.hexcolors.map((color, idx) => (
                        <div key={idx} className="flex flex-col items-center">
                          <div
                            className="w-12 h-12 rounded-full shadow-md hover:scale-110 transition-transform cursor-pointer"
                            style={{ backgroundColor: color }}
                            onClick={() => copyToClipboard(color)}
                          />
                          <span className="text-muted text-sm mt-1">{color}</span>
                          <button
                            className="mt-1 p-1 bg-accent/10 hover:bg-accent/20 rounded-full transition-colors"
                            onClick={() => copyToClipboard(color)}
                          >
                            <Clipboard className="w-4 h-4 text-accent" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <Footer />
      </section>
    </>
  );
}