import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar'; // Import Navbar component
import Footer from './Footer'; // Import the Footer component
import '../colors.css';

export default function Search() {
  const navigate = useNavigate();

  const searchOptions = [
    {
      headline: "Search by Date",
      description: "Find images based on the date they were added.",
      buttonText: "Search by Date",
      onClick: () => navigate('/search/date') // Navigate to SearchByDate component
    },
    {
      headline: "Search by Tags",
      description: "Find images based on specific tags.",
      buttonText: "Search by Tags",
      onClick: () => navigate('/search/tags') // Navigate to SearchByTags component
    },
    {
      headline: "Randomize",
      description: "Discover a random image from our collection.",
      buttonText: "Search by Random",
      onClick: () => navigate('/search/random') // Navigate to SearchByRandom component
    },
    {
      headline: "Search by Color",
      description: "Find images based on specific colors.",
      buttonText: "Search by Color",
      onClick: () => navigate('/search/color') // Navigate to SearchByColor component
    }
  ];

  return (
    <>
      <Navbar />
      <section className="relative text-center py-32 px-4 sm:px-6 lg:px-8 overflow-hidden bg-base">
        <div className="relative z-10 rounded-xl shadow-xl mx-auto my-8 max-w-4xl p-8 bg-card">
          <h2 className="text-4xl font-bold text-center mb-8">Search Options</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {searchOptions.map((option, index) => (
              <div key={index} className="bg-card-dark p-6 rounded-xl shadow-md flex flex-col justify-between">
                <div>
                  <h3 className="text-2xl font-semibold text-accent mb-4">{option.headline}</h3>
                  <p className="text-muted mb-6">{option.description}</p>
                </div>
                <button 
                  className="mt-auto px-4 py-2 bg-accent text-black rounded-lg hover:bg-accent/90 transition-all"
                  onClick={option.onClick}
                >
                  {option.buttonText}
                </button>
              </div>
            ))}
          </div>
        </div><br /><br />

        <Footer />
      </section>
    </>
  );
}