import React, { useState, useEffect } from 'react';
import HeroSection from './HeroSection';
import DailyImage from './DailyImage';
import Navbar from './Navbar';
import Footer from './Footer';
import NextInspirations from './NextInspirations';
import MoreInspirations from './MoreInspirations';
import '../colors.css';

const WelcomePage = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [dailyImage, setDailyImage] = useState(null); // Define dailyImage and setDailyImage

  useEffect(() => {
    // Always load in dark mode
    document.documentElement.classList.remove('light');
    document.documentElement.classList.add('dark');
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    const html = document.documentElement;
    if (isDarkMode) {
      html.classList.remove('dark');
      html.classList.add('light');
    } else {
      html.classList.remove('light');
      html.classList.add('dark');
    }
  };

  useEffect(() => {
    fetch('https://nitter.com/get_daily_image.php')
      .then(response => response.json())
      .then(data => setDailyImage(data))
      .catch(error => console.error('Error fetching daily image:', error));
  }, []);

  return (
    <div className="min-h-screen transition-all duration-500">
      <Navbar /> {/* Removed redundant props as Navbar manages dark mode internally */}
      {dailyImage && <HeroSection />} {/* Removed backgroundImage prop */}
      {dailyImage && <DailyImage dailyImage={dailyImage} />}
      <NextInspirations />
      <MoreInspirations />
      <Footer />
    </div>
  );
};

export default WelcomePage;