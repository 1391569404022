import React, { useState, useEffect } from "react";
import { ArrowLeft, Calendar, Palette, Clipboard } from "lucide-react"; // Import ArrowLeft, Calendar, Palette, and Clipboard components
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Lightbox from './common/Lightbox';
import LoadingState from './common/LoadingState';
import Navbar from './Navbar'; // Import Navbar component
import Footer from './Footer'; // Import Footer component
import { format } from "date-fns";
import '../colors.css';

export default function SearchByRandom() {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState("");
  const navigate = useNavigate(); // Initialize navigate

  const fetchRandomImage = () => {
    setLoading(true);
    setError(null);

    fetch('https://nitter.com/SearchByRandom.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setImage(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching random image:", err);
        setError("An error occurred while fetching data.");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchRandomImage();
  }, []);

  const openLightbox = (url) => {
    setLightboxImage(url);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setLightboxImage("");
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(`Copied ${text} to clipboard`);
  };

  if (loading) return <LoadingState />;
  if (error) return <p className="text-red-500">{error}</p>;

  const {
    link,
    description,
    tags = [],
    hexcolors = [],
    headlines,
    alttext,
    date,
    style,
  } = image;

  const formattedDate = format(new Date(date), "do MMMM yyyy");

  return (
    <>
      <Navbar />
      <div className="container mx-auto p-4 mt-4">
        <button
          onClick={() => navigate('/search')}
          className="flex items-center gap-2 px-4 py-2 bg-accent text-black rounded-lg hover:bg-accent/90 transition-all mb-6"
        >
          <ArrowLeft className="w-5 h-5" />
          Back to Search
        </button>
        <h2 className="text-4xl font-bold text-center mb-8 text-accent">Random Image</h2>
        <div className="flex justify-center mb-6">
          <button
            onClick={fetchRandomImage}
            className="px-4 py-2 bg-accent text-black rounded-lg hover:bg-accent/90 transition-all"
          >
            RANDOMIZE
          </button>
        </div>
        <div id="image-of-the-day" className="bg-card rounded-xl overflow-hidden shadow-xl mx-auto py-2 max-w-screen-xl">
          <div className="relative w-full aspect-video overflow-hidden group">
            <img
              src={`https://nitter.com/${link}`}
              alt={alttext}
              className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105 cursor-pointer"
              onClick={() => openLightbox(`https://nitter.com/${link}`)}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-900/90 via-gray-900/20 to-transparent" />
            <div className="absolute bottom-0 left-0 right-0 p-8">
              <div className="flex items-center gap-6 text-muted">
                <div className="flex items-center">
                  <Calendar className="h-5 w-5 mr-2 text-accent" />
                  <span>{formattedDate}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-2 gap-8 p-8">
            <div className="space-y-6">
              <h2 className="text-4xl font-bold text-base mb-4">{headlines}</h2>
              {description && (
                <div>
                  <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-3">
                    Description
                  </h3>
                  <p className="text-muted leading-relaxed">{description}</p>
                </div>
              )}
              {style && (
                <div>
                  <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-3">
                    Style
                  </h3>
                  <p className="text-muted leading-relaxed">{style}</p>
                </div>
              )}
            </div>
            <div className="space-y-8">
              {tags.length > 0 && (
                <div className="animate-fadeIn">
                  <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-3">
                    Tags
                  </h3>
                  <div className="flex gap-2 flex-wrap">
                    {tags.map((tag, index) => (
                      <span 
                        key={index} 
                        className="bg-gray-700 text-white px-3 py-1 rounded-full text-sm"
                      >
                        {tag} {/* Removed the '#' prefix */}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              {hexcolors.length > 0 && (
                <div className="animate-fadeIn">
                  <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-3">
                    <div className="flex items-center gap-2">
                      <Palette className="h-4 w-4" />
                      <span>Color Palette</span>
                    </div>
                  </h3>
                  <div className="flex flex-wrap gap-4 mb-4">
                    {hexcolors.map((color, index) => (
                      <div key={index} className="flex flex-col items-center">
                        <div
                          className="w-12 h-12 rounded-full shadow-md hover:scale-110 transition-transform cursor-pointer"
                          style={{ backgroundColor: color }}
                          onClick={() => copyToClipboard(color)}
                        />
                        <span className="text-muted text-sm mt-1">{color}</span>
                        <button
                          className="mt-1 p-1 bg-accent/10 hover:bg-accent/20 rounded-full transition-colors"
                          onClick={() => copyToClipboard(color)}
                        >
                          <Clipboard className="w-4 h-4 text-accent" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <Lightbox isOpen={lightboxOpen} onClose={closeLightbox}>
            <img src={lightboxImage} alt="Lightbox" className="max-w-full max-h-full rounded-lg" />
          </Lightbox>
        </div>
      </div>
      <Footer />
    </>
  );
}
