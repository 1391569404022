import React, { useState } from "react";
import { Calendar, Palette, Clipboard, X, ArrowLeft } from "lucide-react"; // Add ArrowLeft
import { useNavigate } from 'react-router-dom'; // Add useNavigate
import Navbar from './Navbar';
import Footer from './Footer';

export default function SearchByDate() {
  const navigate = useNavigate(); // Initialize navigate
  const [date, setDate] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // Add this state

  // Handle date changes from the datepicker
  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  // Fetch images by selected date
  const handleSearch = () => {
    if (!date) {
      alert("Please select a date first.");
      return;
    }
    setLoading(true);
    setError(null);
    setImages([]);

    console.log(`Searching for date: ${date}`); // Debug log

    fetch(`https://nitter.com/SearchByDate.php?date=${date}`)
      .then(async (response) => {
        const data = await response.json();
        console.log('Response data:', data); // Debug log
        
        if (!response.ok) {
          throw new Error(data.details || data.error || `Server error: ${response.status}`);
        }
        return data;
      })
      .then((data) => {
        if (data.error) {
          throw new Error(data.error);
        }
        setImages(Array.isArray(data) ? data : []);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching images by date:", err);
        setError(`Error: ${err.message}`);
        setLoading(false);
      });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(`Copied ${text} to clipboard`);
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto p-4 mt-4">
        {/* Back button */}
        <button
          onClick={() => navigate('/search')}
          className="flex items-center gap-2 px-4 py-2 bg-accent text-black rounded-lg hover:bg-accent/90 transition-all mb-6"
        >
          <ArrowLeft className="w-5 h-5" />
          Back to Search
        </button>

        {/* Header and description */}
        <h2 className="text-4xl font-bold text-center mb-8 text-accent">Search by Date</h2>
        <p className="text-center text-base font-light mb-12 text-muted max-w-2xl mx-auto">
          Explore our design archives by selecting a specific date. Browse through our collection 
          of daily designs and find inspiration from past creations.
        </p>

        {/* Date Picker and Search Button - Centered */}
        <div className="flex flex-col items-center gap-4 mb-12">
          <input
            type="date"
            value={date}
            onChange={handleDateChange}
            className="px-6 py-3 bg-card text-accent rounded-lg border border-accent/20 focus:border-accent 
                      transition-all outline-none hover:border-accent/50 cursor-pointer
                      dark:[color-scheme:dark] [color-scheme:light]"
            style={{
              '::-webkit-calendar-picker-indicator': {
                filter: 'var(--calendar-icon-filter)'
              }
            }}
          />
          <button
            onClick={handleSearch}
            className="px-8 py-3 bg-accent text-black rounded-lg hover:bg-accent/90 
                     transition-all font-medium"
          >
            Search Archives
          </button>
        </div>

        {/* Loading and Error states - Centered */}
        {loading && (
          <div className="text-center">
            <p className="text-accent">Searching archives...</p>
          </div>
        )}
        {error && (
          <div className="text-center">
            <p className="text-red-500">{error}</p>
          </div>
        )}

        {/* Rest of the component */}
        {!loading && !error && images.length > 0 && (
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {images.map((img, index) => (
              <div
                key={index}
                className="relative aspect-square overflow-hidden cursor-pointer group"
                onClick={() => setSelectedImage(img)}
              >
                <img
                  src={`http://nitter.com/${img.link}`}
                  alt={img.alttext || "No alt text"}
                  className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-black/60 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <span className="text-white">View Details</span>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Lightbox */}
        {selectedImage && (
          <div 
            className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-8" 
            onClick={() => setSelectedImage(null)}
          >
            <div 
              className="bg-card rounded-xl overflow-hidden shadow-2xl max-w-6xl w-full max-h-[90vh] overflow-y-auto"
              onClick={e => e.stopPropagation()}
            >
              <div className="relative">
                <img
                  src={`http://nitter.com/${selectedImage.link}`}
                  alt={selectedImage.alttext}
                  className="w-full object-contain"
                />
                <button 
                  className="absolute top-4 right-4 p-2 bg-black/50 text-white rounded-full hover:bg-black/75 transition-colors"
                  onClick={() => setSelectedImage(null)}
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              
              <div className="p-8">
                <h2 className="text-3xl font-bold text-base mb-6">{selectedImage.headlines}</h2>
                
                {selectedImage.description && (
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">Description</h3>
                    <p className="text-muted">{selectedImage.description}</p>
                  </div>
                )}

                {/* Style section */}
                {selectedImage.style && (
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">Style</h3>
                    <p className="text-muted">{selectedImage.style}</p>
                  </div>
                )}

                {/* Tags section */}
                {selectedImage.tags?.length > 0 && (
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">Tags</h3>
                    <div className="flex flex-wrap gap-2">
                      {selectedImage.tags.map((tag, idx) => (
                        <span key={idx} className="bg-gray-700 text-white px-3 py-1 rounded-full text-sm">{tag}</span>
                      ))}
                    </div>
                  </div>
                )}

                {/* Color Palette section */}
                {selectedImage.hexcolors?.length > 0 && (
                  <div>
                    <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-2">
                      <div className="flex items-center gap-2">
                        <Palette className="h-4 w-4" />
                        <span>Color Palette</span>
                      </div>
                    </h3>
                    <div className="flex flex-wrap gap-4">
                      {selectedImage.hexcolors.map((color, idx) => (
                        <div key={idx} className="flex flex-col items-center">
                          <div
                            className="w-12 h-12 rounded-full shadow-md hover:scale-110 transition-transform cursor-pointer"
                            style={{ backgroundColor: color }}
                            onClick={() => copyToClipboard(color)}
                          />
                          <span className="text-muted text-sm mt-1">{color}</span>
                          <button
                            className="mt-1 p-1 bg-accent/10 hover:bg-accent/20 rounded-full transition-colors"
                            onClick={() => copyToClipboard(color)}
                          >
                            <Clipboard className="w-4 h-4 text-accent" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!loading && !error && images.length === 0 && date && (
          <p className="text-white">Press 'Search archives' to find images for {date}.</p>
        )}
      </div>
      <Footer />
    </>
  );
}
