import React, { useState, useEffect } from "react";
import { Calendar, Palette, Clipboard } from "lucide-react";
import { format } from "date-fns";
import '../colors.css';

export default function NextInspirations() {
  const [nextImages, setNextImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("https://nitter.com/get_next_images.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data); // Log the response data
        if (data.error) {
          throw new Error(data.error);
        }
        setNextImages(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching next images:", err);
        setError(err.message);
        setLoading(false);
      });
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(`Copied ${text} to clipboard`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <section className="rounded-xl overflow-hidden shadow-xl mx-1 my-2 bg-base">
      <h3 className="text-2xl font-bold text-base mb-4 p-8">Previous 3</h3>
      <div className="grid md:grid-cols-3 gap-3 p-2">
        {nextImages.map((image, index) => (
          <div key={index} className="bg-card rounded-xl overflow-hidden shadow-xl">
            <div className="relative w-full aspect-video overflow-hidden group">
              <img
                src={`https://nitter.com/${image.link}`}
                alt={image.alttext}
                className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105 cursor-pointer"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900/90 via-gray-900/20 to-transparent" />
            </div>
            <div className="p-8">
              <h2 className="text-2xl font-bold text-base mb-4">{image.headlines}</h2>
              <div className="flex items-center gap-6 text-muted mb-4">
                <div className="flex items-center">
                  <Calendar className="h-5 w-5 mr-2 text-accent" />
                  <span>{format(new Date(image.date), "do MMMM yyyy")}</span>
                </div>
              </div>
              {image.description && (
                <div>
                  <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-3">
                    Description
                  </h3>
                  <p className="text-muted leading-relaxed">{image.description}</p>
                </div>
              )}
              <br />
              {image.style && (
                <div>
                  <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-3">
                    Style
                  </h3>
                  <p className="text-muted leading-relaxed">{image.style}</p>
                </div>
              )}
              <br />
              {image.tags.length > 0 && (
                <div className="animate-fadeIn">
                  <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-3">
                    Tags
                  </h3>
                  <div className="flex gap-2 flex-wrap">
                    {image.tags.map((tag, index) => (
                      <span key={index} className="bg-gray-700 text-white px-3 py-1 rounded-full text-sm">
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              <br />
              {image.hexcolors.length > 0 && (
                <div className="animate-fadeIn">
                  <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-3">
                    <div className="flex items-center gap-2">
                      <Palette className="h-4 w-4" />
                      <span>Color Palette</span>
                    </div>
                  </h3>
                  <div className="flex flex-wrap gap-4 mb-4">
                    {image.hexcolors.map((color, index) => (
                      <div key={index} className="flex flex-col items-center">
                        <div
                          className="w-12 h-12 rounded-full shadow-md hover:scale-110 transition-transform cursor-pointer"
                          style={{ backgroundColor: color }}
                          onClick={() => copyToClipboard(color)}
                        />
                        <span className="text-muted text-sm mt-1">{color}</span>
                        <button
                          className="mt-1 p-1 bg-accent/10 hover:bg-accent/20 rounded-full transition-colors"
                          onClick={() => copyToClipboard(color)}
                        >
                          <Clipboard className="w-4 h-4 text-accent" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}