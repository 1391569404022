import React from 'react';
import Navbar from './Navbar'; // Import Navbar component
import Footer from './Footer';

export default function AboutUs() {
  return (
    <>
      <Navbar />
      <section className="relative text-center py-32 px-4 sm:px-6 lg:px-8 overflow-hidden bg-base">
        <div className="relative z-10 rounded-xl shadow-xl mx-auto my-8 max-w-4xl p-8 bg-card">
          <h2 className="text-4xl font-bold text-center mb-2 p-8">About us</h2>
          <p className="text-center text-base font-light mb-6">
            Welcome to our creative hub where we curate images, color palettes, and daily inspirations. Our goal is to spark new ideas and showcase a world of vibrant possibilities.
          </p>
          <p className="text-muted leading-relaxed mb-6">
            We believe in the power of visual storytelling and strive to feature diverse styles, trends, and concepts. Whether you are a designer, artist, or simply seeking creative inspiration, our site is here to help you explore, learn, and create.
          </p>
          <div className="max-w-4xl mx-auto px-6 py-12 text-base">
            <h2 className="text-2xl font-semibold text-white text-accent mb-4">Our Mission</h2>
            <p className="leading-relaxed mb-6">
              Our goal is to provide a curated collection of stunning visuals, color palettes, and insights into the world of design. 
              Whether you’re an artist, designer, or simply someone who appreciates creativity, we offer a daily dose of inspiration
              to fuel your imagination.
            </p>

            <h2 className="text-2xl font-semibold text-accent text-white mb-4">Our Services</h2>
            <div className="flex flex-wrap justify-center gap-2">
              <div className="flex items-start gap-4 bg-card-dark p-6 rounded-xl shadow-md w-full sm:w-1/2">
                <div>
                  <h3 className="font-semibold text-accent">Curated Galleries</h3>
                  <p className="text-muted">
                    Explore our handpicked collections of images and color palettes. Our curated galleries are designed to provide you with the best visual inspiration, whether you're working on a new project or just looking for fresh ideas.
                  </p>
                </div>
              </div>
              <div className="flex items-start gap-4 bg-card-dark p-6 rounded-xl shadow-md w-full sm:w-1/2">
                <div>
                  <h3 className="font-semibold text-accent">Daily Inspirations</h3>
                  <p className="text-muted">
                    Get inspired by our daily featured images and design insights. Each day, we highlight a new image along with detailed analysis and color profiles to spark your creativity.
                  </p>
                </div>
              </div>
              <div className="flex items-start gap-4 bg-card-dark p-6 rounded-xl shadow-md w-full sm:w-1/2">
                <div>
                  <h3 className="font-semibold text-accent">Color Palettes</h3>
                  <p className="text-muted">
                    Discover beautiful color combinations to enhance your projects. Our color palettes are carefully selected to provide you with harmonious and visually appealing color schemes.
                  </p>
                </div>
              </div>
            </div>

            <h2 className="text-2xl font-semibold text-accent text-white mt-8 mb-4">Our Vision</h2>
            <p className="leading-relaxed text-base text-muted">
              We envision a world where creativity is limitless, and every individual has access to inspiration that fuels their passion. 
              Through a blend of technology and artistry, we strive to make design appreciation more accessible, insightful, and engaging.
            </p>

            <div className="mt-10">
              <p className="text-gray-400 text-sm italic">
                "Every Color Holds a Story, Every Image Whispers a Truth." — Nitter
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
