import React, { useState, useEffect } from "react";
import { Calendar, Palette, Clipboard } from "lucide-react";
import { format } from "date-fns";
import Lightbox from './common/Lightbox';
import LoadingState from './common/LoadingState';
import '../colors.css';

export default function DailyImage() {
  const [dailyImage, setDailyImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState("");

  useEffect(() => {
    fetch("https://nitter.com/get_daily_image.php")
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          throw new Error(data.error);
        }
        setDailyImage(data);
        // Set page background color to first hexcolor
        if (data.hexcolors && data.hexcolors.length > 0) {
          document.body.style.backgroundColor = data.hexcolors[0];
          document.body.style.transition = 'background-color 0.3s ease';
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching daily image:", err);
        setError(err.message);
        setLoading(false);
      });

    // Cleanup function to reset background color when component unmounts
    return () => {
      document.body.style.backgroundColor = '';
      document.body.style.transition = '';
    };
  }, []);

  const openLightbox = (url) => {
    setLightboxImage(url);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setLightboxImage("");
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(`Copied ${text} to clipboard`);
  };

  if (loading) return <LoadingState />;
  if (error) return <p className="text-accent text-center p-4">Error: {error}</p>;

  const {
    link,
    description,
    tags = [],
    hexcolors = [],
    headlines,
    alttext,
    date,
    style,
  } = dailyImage;

  const imageSrc = link?.startsWith('http') ? link : `https://nitter.com/${link}`;
  const formattedDate = format(new Date(date), "do MMMM yyyy");

  return (
    <div className="min-h-screen px-6"> {/* Remove default background color, add min-height */}
      <div id="daily-image-section" className="bg-card rounded-xl overflow-hidden shadow-xl mx-auto my-10 max-w-screen-xl">
        <h1 className="text-4xl font-bold text-base text-center my-4">Image of the day!</h1>
        <div className="relative w-full aspect-video overflow-hidden group">
          <img
            src={imageSrc}
            alt={alttext}
            className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105 cursor-pointer"
            onClick={() => openLightbox(imageSrc)}
          />
          <div className="absolute inset-0 bg-gradient-to-t from-gray-900/90 via-gray-900/20 to-transparent" />
          <div className="absolute bottom-0 left-0 right-0 p-8">
            <div className="flex items-center gap-6 text-muted">
              <div className="flex items-center">
                <Calendar className="h-5 w-5 mr-2 text-accent" />
                <span>{formattedDate}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 gap-8 p-8">
          <div className="space-y-6">
            <h2 className="text-4xl font-bold text-base mb-4">{headlines}</h2>
            {description && (
              <div>
                <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-3">
                  Description
                </h3>
                <p className="text-muted leading-relaxed">{description}</p>
              </div>
            )}
            {style && (
              <div>
                <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-3">
                  Style
                </h3>
                <p className="text-muted leading-relaxed">{style}</p>
              </div>
            )}
          </div>
          <div className="space-y-8">
            {tags.length > 0 && (
              <div className="animate-fadeIn">
                <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-3">
                  Tags
                </h3>
                <div className="flex gap-2 flex-wrap">
                  {tags.map((tag, index) => (
                    <span 
                      key={index} 
                      className="bg-gray-700 text-white px-3 py-1 rounded-full text-sm"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            )}
            {hexcolors.length > 0 && (
              <div className="animate-fadeIn">
                <h3 className="text-sm font-medium text-accent uppercase tracking-wider mb-3">
                  <div className="flex items-center gap-2">
                    <Palette className="h-4 w-4" />
                    <span>Color Palette</span><br /><br />
                  </div>
                </h3>
                <div className="flex flex-wrap gap-4 mb-4">
                  {hexcolors.map((color, index) => (
                    <div key={index} className="flex flex-col items-center">
                      <div
                        className="w-12 h-12 rounded-full shadow-md hover:scale-110 transition-transform cursor-pointer"
                        style={{ backgroundColor: color }}
                        onClick={() => copyToClipboard(color)}
                      />
                      <span className="text-muted text-sm mt-1">{color}</span>
                      <button
                        className="mt-2 p-1"
                        onClick={() => copyToClipboard(color)}
                      >
                        <Clipboard className="h-4 w-4 text-accent" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <Lightbox isOpen={lightboxOpen} onClose={closeLightbox}>
          <img src={lightboxImage} alt="Lightbox" className="max-w-full max-h-full rounded-lg" />
        </Lightbox>
      </div>
    </div>
  );
}